/**
 *
 * Styles for the Header.
 *
 */

.header {
    min-height: 120px;
}

/* purgecss start ignore */
header.headroom {
    @apply transform transition-transform duration-200;
}

header.headroom.headroom--not-top.headroom--unpinned {
    @apply -translate-y-8;
}

header.headroom.headroom--not-top {
    @apply shadow;
}

header.headroom.headroom--top .header__secondbar {
    @apply border-b border-transparent;
}

header.headroom.headroom--not-top .header__secondbar {
    @apply border-gray-alabaster;
}
/* purgecss end ignore */
