.fancy-underline u {
    @apply block relative no-underline;
    &:after {
        content: '';
        @apply absolute bottom-0 left-0 w-full border-b-4 -mb-2 border-red-cardinal
    }

    @media screen and (min-width: 640px) {
        display: inline-block;
    }
}