.testimonial-slider__container {
    max-width: 1300px;
}

.testimonial-slider__inner {
    border: 1px solid #E8E8E8;
}

/* Navigation */
.testimonial-slider__control {
    @apply top-0 mt-0;
}

.testimonial-slider__control::after {
    @apply hidden;
}

.testimonial-slider__control-prev {
    @apply right-full left-auto mr-2;
}

.testimonial-slider__control-next {
    @apply left-full ml-2;
}

@screen lg {
    .testimonial-slider__control-prev {
        @apply mr-12;
    }

    .testimonial-slider__control-next {
        @apply ml-12;
    }
}