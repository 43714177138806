.topnav-sites__selector-icon {
    @apply opacity-0;
}

.topnav-sites__item > a {
    @apply transition duration-200 opacity-50;
}

.topnav-sites__item.active > a {
    @apply text-red-cardinal;
}

.topnav-sites__item.active > a,
.topnav-sites__item.active .topnav-sites__selector-icon {
    @apply opacity-100;
}