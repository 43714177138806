.prose-readmore__morecontent {
    @apply transition-all duration-300 overflow-hidden;
    max-height: 0px;
}

.prose-readmore.expanded .prose-readmore__morecontent {
    max-height: 2000px;
}

.prose-readmore__label::after {
    content: ' More';
}

.prose-readmore.expanded .prose-readmore__label::after {
    content: ' Less';
}