/*
 * Styles for the select field
 */
.input-form-select > div {
    @apply bg-white rounded-5 px-4 border border-gray-suva border-opacity-50 h-10 transition-all duration-200 relative flex items-center;
    &.errors {
        @apply border-red-500;
    }
    &:focus,
    &:hover,
    &:focus-within {
        @apply border-opacity-100;
    }

    /* Add the custom caret */
    /* https://yoksel.github.io/url-encoder/ */
    &:after {
        content: url("data:image/svg+xml,%3Csvg width='15' height='9' viewBox='0 0 15 9' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1080.24103,680.320957 C1080.61606,679.915528 1081.24874,679.890883 1081.65417,680.265911 C1082.02841,680.61209 1082.0782,681.177812 1081.78868,681.581679 L1081.70922,681.679053 L1075.23408,688.67909 C1074.86698,689.075942 1074.25839,689.104845 1073.85615,688.76537 L1073.76719,688.68049 L1067.26721,681.680453 C1066.89141,681.275741 1066.91484,680.64301 1067.31956,680.267208 C1067.69314,679.920315 1068.26101,679.913601 1068.64174,680.232938 L1068.7328,680.319557 L1074.49799,686.528071 L1080.24103,680.320957 Z' transform='translate(-1067 -680)' fill='%23BF1E2D' fill-rule='nonzero'/%3E%3C/svg%3E");
        @apply pointer-events-none absolute my-auto inset-y-0;
        height: 22px;
        right: 20px;
    }
}

.input-form-select > div > select {
    @apply bg-transparent appearance-none flex font-light w-full items-center leading-snug relative transition-all cursor-pointer z-0;
}
/* purgecss end ignore */