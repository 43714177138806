.page-title-header__inner {
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='23' viewBox='0 0 22 23' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cpath id='a' d='M0 .332h22v21H0z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M8.212 5.422a4.107 4.107 0 11-8.21.001 4.107 4.107 0 018.21-.001' fill='%23E8E8E8'/%3E%3C/g%3E%3C/svg%3E");
}

.page-title-header__primarytext {
    @apply text-4xl; /* 36px */
}

.page-title-header__separator {
    border-bottom-width: 5px;
    max-width: 275px;
    @apply border-red-cardinal w-full;
}

/* Modifier: BLOG Heading */
.page-title-header--article .page-title-header__inner {
    @apply text-center;
}

.page-title-header--article .page-title-header__separator {
    max-width: 480px;
    @apply mx-auto;
}
/* End Modifier: BLOG Heading */

@screen lg {
    /* Modifier: BLOG Heading */
    .page-title-header--article .page-title-header__inner {
        @apply py-20;
    }
    /* End Modifier: BLOG Heading */
}

@screen md {
    .page-title-header__primarytext {
        font-size: 44px;
    }
}