/**
 *
 * Styles for the Mobile Nav
 *
 */

/* purgecss start ignore */

/* .mobile-nav {
    top: var(--header-height-default);
} */

.mobile-nav {
    @apply delay-200;
}

.mobile-nav-active .mobile-nav {
    @apply delay-0 opacity-100 visible;
}

.mobile-nav__navwrap {
    @apply transform transition-all duration-300 translate-x-full;
}

.mobile-nav-active .mobile-nav__navwrap {
    @apply delay-200 translate-x-0;
}

/* purgecss end ignore */