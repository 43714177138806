/* Filter result */
.filtertab-search-results__item::before {
    content: '';
    height: 5px;
    margin-bottom: -2px;
    @apply block bg-gray-alabaster w-full absolute bottom-0 left-0;
}

.filtertab-search-results__item {
    @apply text-brown-thunder;
}

.filtertab-search-results__item.active > a {
    @apply text-red-cardinal;
}

.filtertab-search-results__item.active::before {
    @apply bg-red-cardinal;
}

@screen md {
    .filtertab-search-results__item::before {
        @apply opacity-0 bg-red-cardinal;
    }

    .filtertab-search-results__item.active::before {
        @apply opacity-100;
    }
}

/* Result listing */
.result-listing {
    box-shadow: 0 2px 10px 0 rgba(165, 165, 165, .5);
}

.result-listing__figure {
    min-width: 260px;
}

/* Modifier for resource listing */
.result-listing--resource .result-listing__figure {
    @apply border border-gray-alabaster py-12;
}

.result-listing--resource .result-listing__figure > svg {
    @apply w-32 h-32;
}
/* End Modifier for resource listing */

@screen lg {
    .result-listing:hover {
        box-shadow: 0 2px 10px 0 rgba(165, 165, 165, .85);
    }
}