/*
 * Styles for the checkbox field
 */
.input-form-checkbox > .input {
    @apply flex items-center;
}

.input-form-checkbox__cbox {
    @apply w-5 h-5 flex items-center justify-center rounded border border-gray-suva transition-all duration-200 border-opacity-50 relative;
}

.input-form-checkbox__svg {
    @apply transition-opacity duration-200 opacity-0;
}

.input-form-checkbox__label {
    @apply ml-2;
}

.input-form-checkbox__label::selection {
    @apply bg-transparent;
}

.input-form-checkbox__checkbox:checked + .input-form-checkbox__cbox > svg {
    @apply opacity-100;
}

.input-form-checkbox__checkbox:hover + .input-form-checkbox__cbox {
    @apply border-red-cardinal;
}