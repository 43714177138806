/* purgecss start ignore */

/* Hide Ham icon */
.mobile-nav-active .ham-trigger > .ham-trigger__ham {
    @apply opacity-0 invisible;
}

/* Show close svg icon */
.mobile-nav-active .ham-trigger > .ham-trigger__ham-close {
    @apply opacity-100 visible;
}

/* purgecss end ignore */