form input[type=text],
form input[type=password],
form input[type=email],
form input[type=tel],
form input[type=number] {
    @apply bg-white rounded-5 px-4 border border-gray-suva border-opacity-50 h-10 transition-all duration-200 font-light;
    &:hover {
        @apply border-opacity-100;
    }
    &.errors {
        @apply border-red-500;
    }
}