/* purgecss start ignore */
.has-br br {
    @apply hidden;
}

@screen xl {
    .has-br br {
        @apply inline;
    }
}
/* purgecss end ignore */
