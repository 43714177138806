/* purgecss start ignore */
/* Menu <li> */
.main-nav-menu li {
    @apply flex items-center relative;
}

.main-nav-menu__ul > li > a {
    @apply transition-colors duration-200;
}

/* Submenu */
.main-nav-menu__submenu {
    @apply invisible opacity-0;
    @apply bg-red-cardinal transform transition-all duration-200 absolute top-full rounded-lg text-left overflow-hidden -translate-y-2;
    min-width: 260px;
}

/* Submenu link */
.main-nav-menu__submenu > li > a {
    @apply transition-colors duration-200 w-full py-3 px-4 leading-tight text-white font-medium;
}

.main-nav-menu__submenu > li > a:hover,
.main-nav-menu__submenu > li > a:focus {
    @apply bg-red-cardinal-dark;
}

.main-nav-menu__submenu > li:last-child > a {
    @apply rounded-b-lg;
}

.main-nav-menu__droptrigger:focus-within .main-nav-menu__submenu,
.main-nav-menu__droptrigger:hover .main-nav-menu__submenu {
    @apply visible opacity-100 translate-y-0;
}

@screen lg {
    .main-nav-menu__ul > li > a:hover {
        @apply text-red-cardinal;
    }
}
/* purgecss end ignore */