textarea {
    @apply bg-white rounded-5 p-4 border border-gray-suva border-opacity-50 h-10 transition-all duration-200 font-light;
    &.errors {
        @apply border-red-500;
    }
}

textarea:hover,
textarea:focus {
    @apply border-opacity-100;
}