/* purgecss start ignore */
.resources-accordion__titlewrap {
    box-shadow: 0 0 5px 0 rgba(165, 165, 165, .50);
}

.resources-accordion__titlewrap::before {
    content: '';
    width: 10px;
    @apply h-full absolute left-0 top-0 bg-red-cardinal transform transition-all origin-left duration-200 scale-x-0;
}

.resources-accordion__section.open .resources-accordion__titlewrap::before {
    @apply scale-x-100;
}

.resources-accordion__section.open > .resources-accordion__content {
    @apply block;
}

/* Hide plus icon */
.resources-accordion__section.open .resources-accordion__expand-icons > svg:first-child {
    @apply hidden;
}

/* Show minus icon */
.resources-accordion__section.open .resources-accordion__expand-icons > svg:last-child {
    @apply inline;
}

/* Download UI */
.resource-download__inner {
    @apply transition-all duration-200;
    border: 1px solid #E6E6E6;
}

@screen lg {
    .resource-download__inner:hover {
        @apply border-red-cardinal;
    }
}

/* accessibility */
/* .user-is-tabbing .resources-accordion__titlewrap[aria-expanded='false']:focus .resources-accordion__title {
    @apply text-lochmara;
} */
/* End accessibility */
/* purgecss end ignore */
