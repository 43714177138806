.custom-dropdown-wrapper > select {
    @apply relative p-3 w-full bg-white rounded border shadow-none transition-all duration-300 appearance-none cursor-pointer;
}

/* Show shadow */
.custom-dropdown-wrapper:focus-within,
.custom-dropdown-wrapper:hover {
    @apply shadow;
}

/* Update caret color */
.custom-dropdown-wrapper:hover svg {
    @apply text-red-cardinal;
}
