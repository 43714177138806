.hero-homepage {
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='23' viewBox='0 0 22 23' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cpath id='a' d='M0 .332h22v21H0z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M8.212 5.422a4.107 4.107 0 11-8.21.001 4.107 4.107 0 018.21-.001' opacity='.60' fill='%23E8E8E8'/%3E%3C/g%3E%3C/svg%3E");
}

.hero-homepage__inner,
.hero-homepage__figure {
    min-height: 513px;
}

.hero-homepage__circle {
    width: 52px;
    height: 52px;
}

.hero-homepage__circlewrap {
    margin-top: -51px;
}

.hero-video .plyr__video-embed {
    width: 1400px;
}

@media (min-width: 520px) {
    .hero-video .plyr__video-embed {
        width: 1000px;
    }
}

@screen lg {
    .hero-video .plyr__video-embed {
        @apply w-full;
    }
}

