.blockquote-article__wrap {
    border-left-width: 10px;
}

.blockquote-article__author {
    color: #7E7D7E;
}

@screen lg {
    .blockquote-article__quote {
        font-size: 26px;
    }
}