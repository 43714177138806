img[data-src],
img[data-srcset] {
    display: block;
    min-height: 1px;
}

.vanilla-lazy:not(.loaded):not(.vanilla-lazy-bg) {
    @apply opacity-0 invisible;
}

.imagecontainer {
    position: relative;
}

.imagecontainer:before {
    display: block;
    width: 100%;
    content: "";
    padding-bottom: 100%;
    height: 0;
}

.imagecontainer-img {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    object-fit: cover;
    /* stylelint-disable-next-line */
    font-family: "object-fit: cover";
}

/* purgecss start ignore */
.lazyload-fade {
    @apply transition-opacity duration-200;
}

.lazyload-fade.lazyloaded {
   @apply opacity-100;
}
/* purgecss end ignore */
