/**
 * components/global.css
 *
 * Project-wide styles
 *
 */

/* :root {} */

html {
    @apply bg-white;
}
