<template>
  <div class="w-full cursor-pointer transition accelerate resources-accordion__section">
    <a
        href="#"
        @click.prevent="toggle(!visible)"
        class="bg-white px-8 flex w-full items-center outline-none relative resources-accordion__titlewrap">
                <span
                    class="text-shuttle font-medium py-3 flex-auto transition resources-accordion__title">{{
                    name
                  }}</span>
      <span class="text-shuttle py-2 flex-shrink resources-accordion__expand-icons">
                  <inline-svg
                      :src="iconAccordionExpand"
                      :class="{'fill-current': true, 'transform rotate-180': visible}"
                  ></inline-svg>
                </span>
    </a>
  </div>
  <transition name="expand" @enter="enter" @afterEnter="afterEnter" @leave="leave">
  <div
      class="border-l border-r border-b overflow-hidden transition accelerate resources-accordion__content"
      v-if="visible">
    <div class="p-6 lg:p-10">
      <div class="flex flex-wrap -mx-5">
        <slot></slot>
      </div>
    </div>
  </div>
  </transition>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import {inject, ref, onMounted, onBeforeUnmount} from 'vue';
import iconAccordionExpand from '../../../img/icons-svg/accordion-expand.svg';
import {OpenPanel} from './Accordion.vue'

export default {
  name: 'accordion-group',
  setup() {
    const openPanel = inject(OpenPanel)
    const visible = ref(false)

    onMounted(() => {
      if (!openPanel.value) {
        toggle(true)
      }
    })
    onBeforeUnmount(() => {
    })

    const toggle = (value) => {
      visible.value = value
      if (value) {
        openPanel.value = visible
      }
    }

    const afterEnter = (element) => {
      element.style.height = 'auto';
    }

    const enter = (element) => {
      const { height } = getComputedStyle(element);

      element.style.height = 0;

      setTimeout(() => {
        element.style.height = height;
      });
    }

    const leave = (element) => {
      const { height } = getComputedStyle(element);

      element.style.height = height;

      setTimeout(() => {
        element.style.height = 0;
      });
    }

    return {iconAccordionExpand, toggle, visible, afterEnter, enter, leave}
  },
  components: {
    InlineSvg
  },
  props: {
    name: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
/* purgecss start ignore */
.expand-enter-active,
.expand-leave-active {
  transition-duration: 0.3s;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}

.expand-enter,
.expand-leave-active {
  opacity: 0
}
/* purgecss end ignore */
</style>