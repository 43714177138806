/* purgecss start ignore */
.header__search,
/* Hide close svg icon by default */
[data-hs-trigger] > div:last-child,
/* Hide all header menu nav */
.search-active .header__topnav,
/* Hide Site menu */
.search-active .topnav-sites,
/* Hide Search svg icon */
.search-active [data-hs-trigger] > div:first-child {
    @apply opacity-0 invisible;
}

/* Show search form */
.search-active .header__search,
/* Show close svg icon */
.search-active [data-hs-trigger] > div:last-child {
    @apply opacity-100 visible;
}
/* purgecss end ignore */
