.button {
    @apply inline-flex cursor-pointer py-4 px-6 align-middle font-semibold text-sm text-center rounded-5 justify-center transition-all duration-200;
    box-sizing: border-box;
    place-items: center;

    &--red {
        @apply text-white bg-red-cardinal hover:bg-red-cardinal-dark focus:bg-red-cardinal-dark;
    }
    
    &--red[disabled] {
        @apply bg-gray-400 cursor-not-allowed;
    }
    
    &--white {
        @apply text-red-cardinal bg-white;
    }

    &--hollow {
        color: #ffffff;
        border: 1px solid #ffffff;

        &> .button--icon {
            color: theme('colors.red-cardinal.DEFAULT');
        }

        &:hover {
            background: #ffffff;
            color: theme('colors.red-cardinal.DEFAULT');
        }
    }
}

.button > .button-icon {
    margin-right: 10px;
    color: theme('colors.red-cardinal.DEFAULT');
}