.card-article {
    max-width: 360px;
    /* #A5A5A5 */
    box-shadow: 0 2px 10px 0 rgba(165, 165, 165, .50);
}

.card-article:focus {
    /* #A5A5A5 */
    box-shadow: 0 2px 10px 0 rgba(165, 165, 165, .30);
}

.card-article--listing {
    @apply h-full max-w-none;
}

@screen md {
    .card-article:not(.card-article--listing) {
        @apply mx-4;
    }
}

@screen lg {
    .card-article {
        @apply max-w-none;
    }

    .card-article:hover {
        /* #A5A5A5 */
        box-shadow: 0 2px 10px 0 rgba(165, 165, 165, .30);
    }
}
