/*
|-----------------------------------------------------------------------------
|  Video
|-----------------------------------------------------------------------------
*/

/*
Aspect ratio: Wide
*/
.video-aspect-wide {
    padding-top: calc(560 / 960 * 100%);
}

.video-modal__playcontrol {
    @apply bg-brown-thunder w-20 h-20;
    box-shadow: 4px 4px 15px 0 rgba(0, 0, 0, .50);
}

.video-modal__caption {
    @apply text-brown-thunder;
}

/* Red control theme */
.video-modal--red .video-modal__playcontrol {
    @apply bg-red-cardinal;
}

.video-modal--red .video-modal__caption {
    @apply text-red-cardinal;
}

@screen sm {
    .video-modal__playcontrol {
        width: 106px;
        height: 106px;
    }
}

/* purgecss start ignore */
/* Overwrite */
.plyr__control--overlaid{
    @apply bg-red-cardinal !important;
}

.plyr--full-ui input[type=range] {
    @apply text-red-cardinal !important;
}
/* End Overwrite */
/* purgecss end ignore */