/* stylelint-disable */
/* purgecss start ignore */
.fui-hidden {
    display: none
}

.fui-sr-only {
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px
}

.fui-field {
    flex: 1
}

.fui-label-left-input .fui-field-container, .fui-label-right-input .fui-field-container {
    display: flex;
    align-items: baseline
}

.fui-label-left-input .fui-input-container, .fui-label-right-input .fui-input-container {
    flex: 1
}

.fui-label-below-input > .fui-field-container > .fui-input-container > .fui-fieldset > .fui-legend {
    display: block;
    float: left;
    clear: both;
    width: 100%
}

.fui-layout-horizontal .fui-layout-wrap, .fui-row {
    display: flex
}

.fui-row {
    flex-wrap: wrap
}

.fui-i {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 16px;
    color: #2d3748;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0
}

.fui-i *, .fui-i :after, .fui-i :before {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 0 solid #cbd5e0
}

/* .fui-btn {
    @apply button button--red;
} */

.fui-btn:active, .fui-btn:focus, .fui-btn:hover {
    outline: 0;
    text-decoration: none;
    background-color: #e2e2e2
}

.fui-btn.disabled, .fui-btn:disabled {
    opacity: .65;
    pointer-events: none
}

.fui-btn:not(:disabled):not(.disabled) {
    cursor: pointer
}

.fui-loading {
    position: relative;
    pointer-events: none;
    color: transparent !important;
    min-height: 1rem
}

.fui-loading:after {
    position: absolute;
    display: block;
    height: 1rem;
    width: 1rem;
    margin-top: -.5rem;
    margin-left: -.5rem;
    border-radius: 9999px;
    -webkit-animation: loading .5s linear infinite;
    animation: loading .5s linear infinite;
    border-color: transparent transparent #516fea #516fea;
    border-style: solid;
    border-width: 2px;
    content: "";
    left: 50%;
    top: calc(50% - 3px);
    z-index: 1
}

@-webkit-keyframes loading {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes loading {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

.fui-alert {
    padding: 1rem;
    border-radius: .25rem;
    line-height: 1.25rem;
    font-size: .875rem;
    font-weight: 500;
    margin-bottom: 1rem
}

.fui-alert-error {
    background-color: #fdf2f2;
    color: #9b1c1c
}

.fui-alert-success {
    background-color: #f3faf7;
    color: #03543f
}

.fui-btn-container {
    display: flex;
    padding-top: 1rem;
    justify-content: flex-start
}

.fui-btn-container.fui-btn-right {
    justify-content: flex-end
}

.fui-btn-container.fui-btn-center {
    justify-content: center;
}

.fui-btn-container.fui-btn-center > .button {
    @apply w-full;
}

.fui-btn-container.fui-btn-left-right {
    justify-content: space-between
}

.fui-btn-container .fui-btn {
    margin-left: .5rem;
    margin-right: .5rem
}

.fui-submit {
    color: #fff;
    background-color: #516fea;
    border-color: #516fea;
    order: 10
}

.fui-submit.fui-loading:after {
    border-bottom-color: #fff;
    border-left-color: #fff
}

.fui-submit:hover {
    color: #fff;
    background-color: #2349e5
}

.fui-submit:active, .fui-submit:focus {
    border-color: #2349e5;
    box-shadow: 0 0 0 3px rgba(180, 198, 252, .45)
}

.fui-page:not(.fui-hidden) + .fui-page {
    margin-top: 1.5rem
}

.fui-row {
    margin-left: -.5rem;
    margin-right: -.5rem
}

.fui-row:not(:last-child) {
    margin-bottom: 1.25rem
}

.fui-title {
    padding: 0;
    margin: 0 0 1rem;
    font-size: 1.4rem;
    font-weight: 700
}

.fui-page-title {
    padding: 0;
    margin: 0 0 1rem;
    font-size: 1.125rem;
    font-weight: 600
}

.fui-tabs {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    font-size: .875rem;
    border-bottom: 1px solid #cbd5e0
}

.fui-tab {
    color: #718096;
    padding: .5rem 1rem;
    margin-bottom: -1px;
    border: 1px solid transparent
}

.fui-tab a {
    color: inherit;
    text-decoration: none
}

.fui-tab-active {
    font-weight: 500;
    color: #2d3748;
    background-color: #fff;
    border-color: #cbd5e0 #cbd5e0 transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.fui-progress {
    display: flex;
    height: 1.2rem;
    overflow: hidden;
    font-size: .8rem;
    font-weight: 500;
    color: #fff;
    border-radius: .25rem;
    background-color: #e2e8f0
}

.fui-progress-start .fui-progress {
    margin-bottom: 1rem
}

.fui-progress-end .fui-progress {
    margin-top: 1rem
}

.fui-progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: #516fea;
    transition: width .3s ease
}

.fui-field {
    padding-left: .5rem;
    padding-right: .5rem
}

.fui-label-left-input .fui-input-container {
    margin-left: .5rem
}

.fui-label-right-input .fui-input-container {
    margin-right: .5rem
}

.fui-label, .fui-legend {
    font-size: .875rem;
    line-height: 1.25;
    font-weight: 500;
    display: block;
    padding: 0;
    color: #2d3748
}

.fui-label-above-input .fui-label, .fui-label-above-input .fui-legend, .fui-subfield-label-above-input .fui-label, .fui-subfield-label-above-input .fui-legend {
    margin-bottom: .5rem
}

.fui-label-below-input .fui-label, .fui-label-below-input .fui-legend, .fui-subfield-label-below-input .fui-label, .fui-subfield-label-below-input .fui-legend {
    margin-top: .5rem
}

.fui-error .fui-label, .fui-error .fui-legend {
    color: #771d1d;
    border-color: #f8b4b4
}

.fui-required {
    color: #f56565
}

.fui-instructions {
    color: #718096;
    font-size: .875rem;
    display: block
}

.fui-instructions p {
    margin: 0
}

.fui-instructions-above-input .fui-instructions, .fui-instructions-fieldset-start .fui-instructions {
    margin-bottom: .5rem
}

.fui-instructions-below-input .fui-instructions, .fui-instructions-fieldset-end .fui-instructions {
    margin-top: .5rem
}

.fui-fieldset {
    margin: 0;
    padding: 0;
    border: 0
}

.formie-recaptcha-placeholder > * {
    margin-top: 1rem
}

.fui-errors {
    margin: 0;
    padding: 0
}

.fui-error-message {
    color: #f56565;
    font-size: .875rem;
    display: block;
    margin-top: .5rem
}

.fui-checkbox, .fui-radio {
    position: relative;
    font-family: inherit;
    font-size: .875rem;
    line-height: 1.5;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    print-color-adjust: exact
}

.fui-checkbox:not(:last-child), .fui-radio:not(:last-child) {
    margin-bottom: .25rem
}

.fui-layout-horizontal .fui-layout-wrap > .fui-checkbox, .fui-layout-horizontal .fui-layout-wrap > .fui-radio {
    margin-right: 1rem
}

.fui-checkbox input, .fui-radio input {
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px
}

.fui-checkbox input:focus + label:before, .fui-radio input:focus + label:before {
    border-color: #a4cafe;
    box-shadow: 0 0 0 3px rgba(164, 202, 254, .45)
}

.fui-checkbox input:checked + label:before, .fui-radio input:checked + label:before {
    background-color: #516fea;
    border-color: #516fea
}

.fui-checkbox input:active + label:before, .fui-radio input:active + label:before {
    background-color: #f0f1f4
}

.fui-checkbox label, .fui-radio label {
    position: relative;
    padding-left: 1.8rem;
    line-height: 1.5rem;
    display: inline-block;
    transition: all .15s cubic-bezier(.4, 0, .2, 1)
}

.fui-checkbox label:before, .fui-radio label:before {
    position: absolute;
    top: 5px;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    cursor: pointer;
    border: 1px solid #cbd5e0;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 50% 50%;
    transition: all .15s cubic-bezier(.4, 0, .2, 1)
}

.fui-checkbox label:before {
    border-radius: 2px
}

.fui-checkbox input:checked + label:before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    background-size: 8px auto
}

.fui-radio label:before {
    border-radius: 50%
}

.fui-radio input:checked + label:before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
    background-size: 8px auto
}

.fui-input, .fui-select {
    font-family: inherit;
    font-size: .875rem;
    line-height: 1.5;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    width: 100%;
    display: block;
    padding: .5rem .75rem;
    border: 1px solid #cbd5e0;
    border-radius: .25rem;
    background-color: #fff;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.fui-input:focus, .fui-select:focus {
    outline: 0;
    border-color: #a4cafe;
    box-shadow: 0 0 0 3px rgba(164, 202, 254, .45)
}

.fui-input.fui-error, .fui-select.fui-error {
    color: #771d1d;
    border-color: #f8b4b4
}

.fui-input.fui-error:focus, .fui-select.fui-error:focus {
    box-shadow: 0 0 0 3px rgba(248, 180, 180, .45)
}

.fui-input::-webkit-input-placeholder, .fui-select::-webkit-input-placeholder {
    color: #a0aec0
}

.fui-input::-moz-placeholder, .fui-select::-moz-placeholder {
    color: #a0aec0
}

.fui-input:-ms-input-placeholder, .fui-select:-ms-input-placeholder {
    color: #a0aec0
}

.fui-input::-ms-input-placeholder, .fui-select::-ms-input-placeholder {
    color: #a0aec0
}

.fui-input::placeholder, .fui-select::placeholder {
    color: #a0aec0
}

.fui-input[type=file], .fui-select[type=file] {
    height: auto
}

.fui-input[type=color], .fui-select[type=color] {
    height: 39px;
    padding: .1rem
}

@media (max-width: 639px) {
    .fui-input, .fui-select {
        font-size: 16px
    }
}

.fui-name-prefix, .fui-phone-country {
    flex: 0 0 auto
}

.fui-repeater-row {
    position: relative;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid #cbd5e0;
    border-radius: .25rem
}

.fui-repeater-add-btn {
    position: relative;
    padding-left: 2rem
}

.fui-repeater-add-btn:after {
    content: "";
    position: absolute;
    top: .75rem;
    left: .75rem;
    width: 14px;
    height: 14px;
    display: block;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg aria-hidden='true' data-prefix='far' data-icon='plus' class='svg-inline--fa fa-plus fa-w-12' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'%3E%3Cpath fill='currentColor' d='M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z'/%3E%3C/svg%3E")
}

.fui-repeater-remove-btn {
    position: absolute;
    top: 0;
    right: -14px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 50%;
    height: 0;
    width: 0;
    padding: 13px;
    text-indent: -9999px;
    border: 1px solid #cbd5e0
}

.fui-repeater-remove-btn:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 9px;
    height: 14px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: block;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg aria-hidden='true' data-prefix='far' data-icon='times' class='svg-inline--fa fa-times fa-w-10' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E")
}

.fui-hr {
    border: 0;
    width: 100%
}

.fui-select[multiple], .fui-select[size] {
    height: auto
}

.fui-select[multiple] option, .fui-select[size] option {
    padding: .1rem .4rem
}

.fui-select:not([multiple]):not([size]) {
    padding-right: 2rem;
    background-position: right .25rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='%239fa6b2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")
}

.fui-select::-ms-expand {
    display: none
}

.fui-table {
    width: 100%;
    margin-bottom: 1rem;
    border-collapse: collapse
}

.fui-table th {
    text-align: inherit
}

.fui-table td, .fui-table th {
    padding: .2rem
}

.fui-table td:first-child, .fui-table th:first-child {
    padding-left: 0
}

.fui-table td:last-child, .fui-table th:last-child {
    padding-right: 0
}

.fui-group {
    padding: 1rem;
    border: 1px solid #cbd5e0;
    border-radius: .25rem
}

.fui-rich-text {
    position: relative
}

.fui-rich-text-toolbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 3px 3px 0 0;
    padding: 4px 8px;
    align-items: center;
    flex-wrap: wrap;
    display: flex;
    z-index: 1;
    border-bottom: 1px solid rgba(49, 49, 93, .15);
}

.fui-rich-text-content {
    padding-top: 50px;
    min-height: 10rem
}

.fui-rich-text-content p {
    margin: 0 0 1rem
}

.fui-rich-text-button {
    background: transparent;
    color: #1c2e36;
    border-radius: 3px;
    padding-left: 4px;
    padding-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    text-align: center;
    height: 32px;
    width: 32px;
    margin: 0 2px;
    cursor: pointer
}

.fui-rich-text-button:hover {
    background-color: #edf3fa
}
/* stylelint-enable */
/* purgecss end ignore */