/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 *
 * You can see the styles here:
 * Preflight will be injected here
 */
@import 'tailwindcss/base';

/**
 * Site Base
 *
 */
@import './base/index.css';

/**
 * This injects any component classes registered by plugins.
 *
 */
@import 'tailwindcss/components';

 /**
 * Vendors
 *
 */
@import './vendors/index.css';

/**
 * Here you would add any of your custom component classes; stuff that you'd
 * want loaded *before* the utilities so that the utilities could still
 * override them.
 *
 */

@import "./components/partials/header.css";
@import "./components/partials/headerSearch.css";
@import "./components/partials/ham.css";
@import "./components/partials/mobileNav.css";
@import "./components/partials/topnavSites.css";
@import "./components/partials/mainNavMenu.css";
@import "./components/partials/footer.css";

/* Buttons */
@import './components/ui/buttons/index.css';
@import './components/ui/forms/index.css';
@import './components/ui/cards/index.css';
@import './components/ui/heroes/index.css';
@import './components/ui/cta/index.css';
@import './components/ui/headertitle/index.css';
@import './components/ui/modules/index.css';

/**
 * Include styles for individual pages
 *
 */
 @import './pages/index.css';

/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 *
 */
@import 'tailwindcss/utilities';

/**
 * Here you would add any custom utilities you need that don't come out of the
 * box with Tailwind.
 */

/**
 * Site helper classes
 *
 */
@import './helper-classes/lazy.css';
@import './helper-classes/br.css';
@import "./helper-classes/fancy-underline.css";